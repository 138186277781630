import { createSelector } from '@reduxjs/toolkit'

export const selectContract = state => state.contract

export const selectContractData = createSelector(
  selectContract,
  contract => contract?.data
)

export const selectUtmCampaignName = createSelector(
  selectContractData,
  contract => contract?.campaignData?.name
)
