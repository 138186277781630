import { createSelector } from '@reduxjs/toolkit'
import {
  FEATURE,
  PRICE,
  PROVIDER,
  RATING,
  SELECTRA_SCORE
} from '@root/constants'
import {
  selectFiltersData,
  selectSortCriteria,
  selectTemporaryFilters,
  selectTemporarySortCriteria
} from '../filters/filtersSelectors'
import { getCheckedOffers, getCommonOffersId } from '../filters/filtersHelper'
import { selectUserCurrentEnergyType } from '@root/reducers/user/userSelectors'
import { t } from 'i18next'

export const selectOffers = state => state.offers

export const selectOffersData = createSelector(
  selectOffers,
  selectUserCurrentEnergyType,
  (offers, energyType) => {
    if (!Object.keys(offers ?? {}).length) {
      return {}
    }

    return offers[energyType] || {}
  }
)

export const selectOffersDataOffers = createSelector(
  selectOffersData,
  offersData => offersData?.offers || {}
)

export const selectOffersReferenceOffer = createSelector(
  selectOffersDataOffers,
  offersData => offersData?.referenceOffer || {}
)

export const selectOffersPostData = createSelector(
  selectOffersData,
  offersData => offersData?.postData || {}
)

export const selectOffersPostDataKnowConsumption = createSelector(
  selectOffersPostData,
  postData => postData?.knowConsumption
)

export const selectOffersHighlightedOffers = createSelector(
  selectOffersDataOffers,
  offersData => {
    if (!offersData?.highlighted) {
      return []
    }

    return offersData.highlighted.map(offer => ({
      ...offer,
      tags: offer.tags
        ? offer.tags.map(tag => ({
          key: tag,
          label: t(`productList:highlighted.tags.${tag}`)
        }))
        : []
    }))
  }
)

export const selectCurrentOffersIds = createSelector(
  selectFiltersData,
  filters => {
    const checkedProviderOffers = getCheckedOffers({
      filters,
      filterType: PROVIDER
    }).flat()

    const checkedFeatureOffers = getCheckedOffers({
      filters,
      filterType: FEATURE
    })

    const featureOffersIds = getCommonOffersId({
      checkedOffers: checkedFeatureOffers
    })

    if (!featureOffersIds.length) return checkedProviderOffers
    if (!checkedProviderOffers.length) return featureOffersIds

    const offerIds = checkedProviderOffers.filter(offer =>
      featureOffersIds.includes(offer)
    )

    return offerIds
  }
)

export const selectCurrentOffers = createSelector(
  [
    selectOffersDataOffers,
    selectCurrentOffersIds,
    selectSortCriteria,
    (state, energyType) => energyType
  ],
  (offersData, currentOffersIds, sortCriteria) => {
    let currentOffers = offersData?.result

    if (currentOffersIds.length) {
      currentOffers = offersData?.result.filter(({ idOffer }) =>
        currentOffersIds.includes(Number(idOffer))
      )
    }

    const sortedOffers = [...currentOffers].sort((a, b) => {
      if (sortCriteria === PRICE) {
        return Number(a.globalPrice) - Number(b.globalPrice)
      } else if (sortCriteria === RATING) {
        return b.rate - a.rate
      } else if (sortCriteria === SELECTRA_SCORE) {
        return a.score?.localeCompare(b.score)
      }
      return 0
    })

    return sortedOffers
  }
)

export const selectTemporaryCurrentOffers = createSelector(
  selectTemporaryFilters,
  selectOffersDataOffers,
  selectSortCriteria,
  selectTemporarySortCriteria,
  (temporaryFilters, offersData, sortCriteria, temporarySortCriteria) => {
    const checkedProviderOffers = getCheckedOffers({
      filters: temporaryFilters,
      filterType: PROVIDER
    }).flat()

    const checkedFeatureOffers = getCheckedOffers({
      filters: temporaryFilters,
      filterType: FEATURE
    })

    const featureOffersIds = getCommonOffersId({
      checkedOffers: checkedFeatureOffers
    })

    if (!featureOffersIds.length && !checkedProviderOffers.length && sortCriteria !== temporarySortCriteria) {
      return offersData?.result.length
    }

    if (!featureOffersIds.length) return checkedProviderOffers.length
    if (!checkedProviderOffers.length) return featureOffersIds.length

    const offerIds = checkedProviderOffers.filter(offer =>
      featureOffersIds.includes(offer)
    )

    let currentOffers = offersData?.result

    if (offerIds.length) {
      currentOffers = offersData?.result.filter(({ idOffer }) =>
        offerIds.includes(Number(idOffer))
      )
    }

    return currentOffers.length
  }
)
