import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { useForm } from 'react-hook-form'
import {
  selectFeatureFilters,
  selectFiltersData,
  selectProviderFilters,
  selectTemporaryFilters
} from '@root/reducers/filters/filtersSelectors'
import {
  initFilters,
  resetFilters,
  toggleFilter,
  updateFilters,
  updateSortCriteria,
  updateTemporarySortCriteria
} from '@root/reducers/filters/filtersSlice'
import {
  selectOffersData,
  selectOffersDataOffers
} from '@root/reducers/offers/offersSelectors'
import useBreakpoint from '@hooks/useBreakpoint'
import { selectShouldShowMonthlyPrices } from '@root/reducers/config/configSelectors'
import { toFixed } from '@root/utilities/number-utils'

const useProductList = () => {
  const { t } = useTranslation(['productList', 'estimationForm'])
  const dispatch = useDispatch()
  const breakpoint = useBreakpoint()

  const filters = useSelector(selectFiltersData)
  const featureFilters = useSelector(selectFeatureFilters)
  const providerFilters = useSelector(selectProviderFilters)
  const temporaryFilters = useSelector(selectTemporaryFilters)
  const shouldShowMonthlyPrices = useSelector(selectShouldShowMonthlyPrices)
  const { displayedFilters } = useSelector(selectOffersData)

  const {
    annualCost: annualBudget,
    numberOfOffers: totalOffersFound,
    result: offerList,
    numberOfProviders: totalProviders
  } = useSelector(selectOffersDataOffers)

  const annualCost =
    annualBudget !== 0 ? annualBudget : offerList[0]?.globalEconomie

  const totalCost = shouldShowMonthlyPrices
    ? toFixed(annualCost / 12)
    : annualCost

  const annualSavings = offerList[0]?.globalEconomie ?? 0

  const { control } = useForm({
    shouldUnregister: false
  })

  const handleFilterClick = filterKey => {
    dispatch(toggleFilter({ filterKey, stateKey: 'data' }))
    dispatch(updateFilters({ filterKey, stateKey: 'data' }))
  }

  const handleSortCriteria = sortCriteria => {
    dispatch(updateSortCriteria({ sortCriteria }))
  }

  const handleResetFilters = () => {
    dispatch(resetFilters({ stateKey: 'temporaryFilters' }))
    dispatch(initFilters(displayedFilters))
  }

  const handleFilterClickMobile = filterKey => {
    dispatch(toggleFilter({ filterKey, stateKey: 'temporaryFilters' }))
    dispatch(updateFilters({ filterKey, stateKey: 'temporaryFilters' }))
  }

  const handleSortChangeMobile = sortCriteria => {
    dispatch(updateTemporarySortCriteria(sortCriteria))
  }

  return {
    t,
    filters,
    control,
    totalCost,
    annualSavings,
    totalOffersFound,
    totalProviders,
    featureFilters,
    providerFilters,
    temporaryFilters,
    handleResetFilters,
    handleSortCriteria,
    handleSortChangeMobile,
    handleFilterClick,
    handleFilterClickMobile,
    breakpoint,
    dispatch,
    displayedFilters
  }
}

export default useProductList
