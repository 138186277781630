import Button from '@components/Button/Button'
import Icon from '@components/Icon'

import classes from './CTAProviderSubscriptionButton.module.scss'
import { useTranslation } from 'react-i18next'
import useCheckout from '@features/Checkout/useCheckout'
import { useSiteCountry } from '@hooks/useSiteCountry'
import { useSelector } from 'react-redux'
import { selectUtmCampaignName } from '@root/reducers/contract/contractSelectors'

const CTAProviderSubscriptionButton = ({
  withIcon = false,
  color = 'primary',
  url,
  hidden
}) => {
  const { t } = useTranslation(['cta'])
  const { providerName } = useCheckout()
  const country = useSiteCountry()
  const buttonId = `${providerName?.toLowerCase()}-subscribe-${country}`
  const utmCampaignName = useSelector(selectUtmCampaignName)

  if (hidden || !url) {
    return null
  }

  const urlWithUtmCampaignName = `${url}${
    utmCampaignName ? `?utm_campaign=${utmCampaignName}` : ''
  }`

  console.log(urlWithUtmCampaignName)

  return (
    <Button
      id={buttonId}
      pill
      block
      size='md'
      color={color}
      href={urlWithUtmCampaignName}
      target='_blank'
    >
      {t('cta:visit supplier website')}
      {withIcon
        ? (
          <Icon
            iconId='icon-arrow-right'
            fill='primary'
            size='md'
            position='right'
            extraClasses={[classes.CTAProviderSubscriptionButton__icon]}
          />
          )
        : null}
    </Button>
  )
}

export { CTAProviderSubscriptionButton as default }
