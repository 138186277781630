// Contact
const PHONE_NUMBER = '09 87 67 94 48'
const PHONE_NUMBER_PROPANE = '01 82 88 79 26'
const PHONE_NUMBER_ALL_WEEK = '09 71 07 12 22'
const EMAIL_CONTACT = 'contact@selectra.info'

// Country sites IDs
const FRANCE_ID = 1
const ITALY_ID = 2
const BELGIUM_ID = 6
const JAPAN_ID = 9
const SPAIN_ID = 3
const PORTUGAL_ID = 11

const PROVIDER = 'provider'
const FEATURE = 'feature'

const PRICE = 'price'
const RATING = 'rating'
const SELECTRA_SCORE = 'score'

const ELEC = 'elec'
const GAS = 'gas'
const DUAL = 'dual'

const BASE = 'base'
const HPHC = 'hphc'

const GAS_ENERGY_TYPES = [GAS, DUAL]
const ELEC_ENERGY_TYPES = [ELEC, DUAL]

const NEW_HOUSE = 'new'
const MOVING_SITUATION = 'mes'
const PROVIDER_CHANGE = 'cdf'

const ONE_YEAR_DAYS = 365
const ONE_YEAR_PERIOD = 12

const IS_MOBILE_OR_TABLET = ['xs', 'sm']

const SENDING_BLUE_URLS = {
  elec: 'https://energie.selectra.info/electricite',
  gas: 'https://energie.selectra.info/gaz',
  dual: 'https://energie.selectra.info/electricite-gaz'
}

const SENDING_BLUE_EVENT_NAME = 'energy_comparator'
const SENDING_BLUE_TEMPLATE_ID = 707
const SENDING_BLUE_UTM_INFO = {
  utm_source: 'emailing',
  utm_medium: 'email'
}
const SENDING_BLUE_UTM_CAMPAIGN = 'email-sending'

const FORM_PAGE = 'form'
const PROFESSIONALS_PAGE = 'pro'
const PRODUCT_LIST_PAGE = 'productList'
const PRODUCT_PAGE = 'product'
const CHECKOUT_PAGE = 'checkout'

const STANDARDIZED_SHEET_PAGE = {
  OFFER_SPECS: 'offerSpecs',
  FR_GAS_TABLE_TITLES: 'Conso'
}

const SEARCH_PARAMS_VALUES = {
  ANNUAL_CONSO_ELEC: 'car_base',
  CONSO_HP: 'car_hp',
  CONSO_HC: 'car_hc',
  ANNUAL_CONSO_GAS: 'car_gas',
  TARIFF_OPTION: 'tariff_option',
  SUBSCRIBED_POWER: 'power',
  CITY_ID: 'city_id',
  HOUSE_TYPE: 'house_type',
  RESIDENT: 'resident',
  KNOW_CONSUMPTION: '1',
  CITY_INSEE: 'insee'
}

export {
  PHONE_NUMBER,
  PHONE_NUMBER_PROPANE,
  PHONE_NUMBER_ALL_WEEK,
  EMAIL_CONTACT,
  PROVIDER,
  FEATURE,
  PRICE,
  RATING,
  SELECTRA_SCORE,
  FRANCE_ID,
  ITALY_ID,
  BELGIUM_ID,
  JAPAN_ID,
  SPAIN_ID,
  PORTUGAL_ID,
  ELEC,
  GAS,
  DUAL,
  BASE,
  HPHC,
  GAS_ENERGY_TYPES,
  ELEC_ENERGY_TYPES,
  NEW_HOUSE,
  MOVING_SITUATION,
  PROVIDER_CHANGE,
  ONE_YEAR_DAYS,
  ONE_YEAR_PERIOD,
  IS_MOBILE_OR_TABLET,
  SENDING_BLUE_URLS,
  SENDING_BLUE_EVENT_NAME,
  SENDING_BLUE_TEMPLATE_ID,
  SENDING_BLUE_UTM_INFO,
  SENDING_BLUE_UTM_CAMPAIGN,
  FORM_PAGE,
  PROFESSIONALS_PAGE,
  PRODUCT_LIST_PAGE,
  PRODUCT_PAGE,
  CHECKOUT_PAGE,
  STANDARDIZED_SHEET_PAGE,
  SEARCH_PARAMS_VALUES
}
