import { createSelector } from '@reduxjs/toolkit'

export const selectCurrentOffer = state => state.currentOffer

export const selectCurrentOfferData = createSelector(
  selectCurrentOffer,
  (currentOffer) => currentOffer?.data
)

export const selectCurrentOfferDataAdditionalCosts = createSelector(
  [selectCurrentOfferData],
  (currentOffer) => currentOffer?.additionnalCosts ?? []
)

export const selectCurrentOfferDataElecPromotions = createSelector(
  [selectCurrentOfferData],
  (currentOffer) => {
    return currentOffer?.elecPromotions
  }
)

export const selectCurrentOfferDataGasPromotions = createSelector(
  [selectCurrentOfferData],
  (currentOffer) => {
    return currentOffer?.gasPromotions
  }
)

export const selectCurrentOfferSelectraScore = createSelector(
  [selectCurrentOfferData],
  currentOffer => {
    return currentOffer?.score
  }
)
