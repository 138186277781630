import { parsePrice, toFixed } from './number-utils'

const getGasTotalPriceConsumptionByCountry = ({
  country = 'fr',
  gasTotalAmount,
  gasPriceAboCalculated,
  globalPrice,
  elecTotalPriceBase,
  elecTotalPriceHP,
  elecTotalPriceHC,
  additionalCosts
}) => {
  if (country === 'it') {
    return toFixed(
      parsePrice(globalPrice) -
        parsePrice(gasPriceAboCalculated) -
        parsePrice(elecTotalPriceBase) -
        parsePrice(elecTotalPriceHP) -
        parsePrice(elecTotalPriceHC) -
        parsePrice(additionalCosts)
    )
  }

  if (country === 'fr') {
    return toFixed(
      parsePrice(gasTotalAmount) -
        parsePrice(gasPriceAboCalculated) -
        parsePrice(additionalCosts)
    )
  }
}

const generatePricesData = ({
  tariffOption,
  priceBase,
  priceHP,
  priceHC,
  gasPriceKwhCalculatedGlobal,
  totalPriceAbo,
  totalPriceBase,
  totalPriceHP,
  totalPriceHC,
  elecTotalPrice,
  gasPriceAboCalculated,
  gasTotalAmount,
  hopConfig,
  options = {},
  additionalCosts,
  additionalCostsList,
  discounts,
  energyType
}) => {
  const { globalPrice, country } = options

  totalPriceAbo = parsePrice(totalPriceAbo)
  totalPriceBase = parsePrice(totalPriceBase)
  totalPriceHP = parsePrice(totalPriceHP)
  totalPriceHC = parsePrice(totalPriceHC)
  additionalCosts = parsePrice(additionalCosts)
  gasTotalAmount = parsePrice(gasTotalAmount)
  gasPriceAboCalculated = parsePrice(gasPriceAboCalculated)

  const getMonthlyPrice = (price, paymentsPerYear) =>
    Number(toFixed(price / paymentsPerYear))

  const getElecTotalBase = () =>
    toFixed(totalPriceAbo + totalPriceBase + additionalCosts)

  const getElecTotalHPHC = () =>
    toFixed(totalPriceAbo + totalPriceHP + totalPriceHC + additionalCosts)

  const getElecTotalConsumption = () => {
    return tariffOption === 'base'
      ? toFixed(totalPriceBase + additionalCosts)
      : toFixed(totalPriceHP + totalPriceHC + additionalCosts)
  }

  const getDualTotalConsumption = () => {
    return tariffOption === 'base'
      ? toFixed(totalPriceBase + additionalCosts + gasTotalPriceConsommation)
      : toFixed(
        totalPriceHP +
            totalPriceHC +
            additionalCosts +
            gasTotalPriceConsommation
      )
  }

  const getGasTotalPriceConsumption = () => {
    return getGasTotalPriceConsumptionByCountry({
      globalPrice,
      country,
      gasTotalAmount,
      gasPriceAboCalculated,
      elecTotalPriceBase,
      elecTotalPriceHP,
      elecTotalPriceHC,
      additionalCosts
    })
  }

  const getPromotions = () =>
    additionalCostsList?.map(promo => {
      return {
        text: promo.text,
        amount: +promo.amount
      }
    }) ?? []

  const promotions = getPromotions()

  const getPromotionsAmount = () =>
    promotions.reduce((accumulator, promo) => accumulator + promo.amount, 0)

  const getElecDiscountsAmount = () =>
    discounts?.elec?.reduce((accumulator, discount) => accumulator + discount.value, 0) ?? 0

  const getGasDiscountsAmount = () =>
    discounts?.gas?.reduce((accumulator, discount) => accumulator + discount.value, 0) ?? 0

  const getDualTotalAbo = () => toFixed(totalPriceAbo + gasPriceAboCalculated)

  const getDualTotal = () => {
    return tariffOption !== 'hphc'
      ? toFixed(elecTotalBase + gasTotal)
      : toFixed(elecTotalHPHC + gasTotal)
  }

  const getElecTotal = () =>
    tariffOption !== 'hphc' ? elecTotalBase : elecTotalHPHC

  const promotionsAmount = getPromotionsAmount()
  const elecDiscountsAmount = getElecDiscountsAmount()
  const gasDiscountsAmount = getGasDiscountsAmount()
  const dualDiscountsAmount = elecDiscountsAmount + gasDiscountsAmount
  const dualPromotionsAmount = energyType === 'dual' ? promotionsAmount : 0
  const gasPromotionsAmount = energyType === 'gas' ? promotionsAmount : 0
  const elecPromotionsAmount = energyType === 'elec' ? promotionsAmount : 0

  const elecTotalPriceAbo = Number(toFixed(totalPriceAbo))
  const elecTotalPriceBase = Number(toFixed(totalPriceBase))
  const elecTotalPriceHP = Number(toFixed(totalPriceHP))
  const elecTotalPriceHC = Number(toFixed(totalPriceHC))
  const elecPrice = Number(toFixed(elecTotalPrice)) + elecPromotionsAmount + elecDiscountsAmount
  const gasTotal = Number(toFixed(gasTotalAmount)) + gasPromotionsAmount + gasDiscountsAmount
  const gasTotalPriceAbo = Number(toFixed(gasPriceAboCalculated))
  const elecTotalBase = Number(getElecTotalBase())
  const elecTotalHPHC = Number(getElecTotalHPHC())
  const elecTotalConsumption = Number(getElecTotalConsumption())
  const gasTotalPriceConsommation = Number(getGasTotalPriceConsumption())
  const dualTotalConsumption = Number(getDualTotalConsumption())
  const dualTotalAbo = Number(getDualTotalAbo())
  const dualTotal = Number(getDualTotal()) + dualPromotionsAmount + dualDiscountsAmount
  const elecTotal = Number(getElecTotal()) + elecPromotionsAmount + elecDiscountsAmount

  const paymentsPerYear = hopConfig?.paymentsPerYear ?? 12

  const prices = {
    elec_kwhPriceBase: parsePrice(priceBase),
    elec_kwhPriceHP: parsePrice(priceHP),
    elec_kwhPriceHC: parsePrice(priceHC),
    gas_khwPrice: parsePrice(gasPriceKwhCalculatedGlobal),
    yearly: {
      elec_totalPriceAbo: elecTotalPriceAbo,
      elec_totalPriceBase: elecTotalPriceBase,
      elec_totalPriceHP: elecTotalPriceHP,
      elec_totalPriceHC: elecTotalPriceHC,
      elecTotal,
      elec_total_consumption: elecTotalConsumption,
      elecPrice,

      gas_totalPriceConsommation: gasTotalPriceConsommation,
      gas_totalPriceAbo: gasTotalPriceAbo,
      gasTotal,

      dualTotal,
      dual_total_consumption: dualTotalConsumption,
      dual_totalPriceAbo: dualTotalAbo
    },
    monthly: {
      elec_totalPriceAbo: getMonthlyPrice(elecTotalPriceAbo, paymentsPerYear),
      elec_totalPriceBase: getMonthlyPrice(elecTotalPriceBase, paymentsPerYear),
      elec_totalPriceHP: getMonthlyPrice(elecTotalPriceHP, paymentsPerYear),
      elec_totalPriceHC: getMonthlyPrice(elecTotalPriceHC, paymentsPerYear),
      elecTotal: getMonthlyPrice(elecTotal, paymentsPerYear),
      elecPrice: getMonthlyPrice(elecPrice, paymentsPerYear),

      gas_totalPriceAbo: getMonthlyPrice(gasTotalPriceAbo, paymentsPerYear),
      gas_totalPriceConsommation: getMonthlyPrice(
        gasTotalPriceConsommation,
        paymentsPerYear
      ),
      gasTotal: getMonthlyPrice(gasTotal, paymentsPerYear),

      dualTotal: getMonthlyPrice(dualTotal, paymentsPerYear)
    },
    promotions
  }

  return prices
}

export { generatePricesData as default }
