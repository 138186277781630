const endpoints = {
  'energy-core': {
    comparator: {
      getOfferData: '/offres-electricite/{#provider-slug}/{#offer-slug}',
      loadCities: '/api/load-cities',
      getAutoUpdateData: '/api/autodata',
      eld: '/api/checkEld',
      eldHasOffer: '/api/eldHasOffer',
      paginatedReviews: '/api/load-ratings/',
      v2: '/api',
      zohoSalesSave: '/api/zoho',
      japaneseCities: '/api/v2/cities-japan',
      cities: '/api/v2/cities',
      cityInsee: '/api/v2/city',
      cityByInsee: '/api/city-by-insee',
      cityById: '/api/city-by-id',
      iban: '/api/iban',
      contacts: '/api/contacts',
      getComparatorOfferData: '/api/offers',
      pdl: '/api/pdl',
      consultation: '/api/consultation',
      site: '/api/site',
      estimation: '/api/estimate',
      compare: '/api/compare',
      compareById: '/api/compare/{#offer-id}',
      reportError: '/api/errors',
      regionalOffers: '/api/v2/regional-offers',
      offerMonthlyPayment: '/api/offer-monthly-payment',
      estimationV3: '/api/v3/estimation',
      standardizedSheet: '/api/pdf'
    },
    address: {
      addressSearch: '/search',
      addressReverse: '/reverse'
    },
    dialga: {
      dialgaCurrentOpenDepartments: '/api/schedules/current_open_departments',
      dialgaScheduleOnDate: '/api/schedules/schedule_on_date'
    },
    hop: {
      hopSalesSave: '/sales/save',
      hopSalesComparator: '/sales/comparator',
      offerConfigurations: '/sales/offer-information'
    },
    customerService: {
      getCustomerInfo: '/api/get-customer-info/customer-consents'
    }
  },
  default: {
    comparator: {
      getOfferData: '/offres-electricite/{#provider-slug}/{#offer-slug}',
      loadCities: '/api/loadCities_v2',
      getAutoUpdateData: '/api/getAutoUpdateDatas',
      eld: '/api/checkEld',
      eldHasOffer: '/api/eldHasOffer',
      paginatedReviews: '/api/load_next_rating/',
      v2: '/api/v2',
      zohoSalesSave: '/api/v2/zoho',
      japaneseCities: '/api/v2/cities-japan',
      cities: '/api/v2/cities',
      cityInsee: '/api/v2/city',
      fetchPDL: '/api/v2/pdl',
      iban: '/api/v2/iban',
      logPDL: '/api/v2/browsery-interactions/address-pdl-information',
      contacts: '/api/v2/contacts',
      getComparatorOfferData: '/api/v2/offers',
      consultation: '/api/v2/consultation',
      site: '/api/v2/site',
      estimation: '/api/v2/estimate',
      compare: '/api/v2/compare',
      compareById: '/api/v2/compare/{#offer-id}',
      reportError: '/api/v2/errors',
      regionalOffers: '/api/v2/regional-offers',
      offerMonthlyPayment: '/api/v2/offer-monthly-payment',
      estimationV3: '/api/v3/estimation',
      standardizedSheet: '/api/v2/standardized-sheet'
    },
    address: {
      addressSearch: '/search',
      addressReverse: '/reverse'
    },
    dialga: {
      dialgaCurrentOpenDepartments: '/api/schedules/current_open_departments',
      dialgaScheduleOnDate: '/api/schedules/schedule_on_date'
    },
    hop: {
      hopSalesSave: '/sales/save',
      hopSalesComparator: '/sales/comparator',
      offerConfigurations: '/sales/offer-information'
    },
    customerService: {
      getCustomerInfo: '/api/get-customer-info/customer-consents'
    }
  }
}

export default endpoints
