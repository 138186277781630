import { FEATURE, PROVIDER } from '@root/constants'

export const getCommonOffersId = ({ checkedOffers }) =>
  checkedOffers?.reduce((prev, current) => {
    if (!prev.length) return current
    return current.filter(id => prev.includes(id))
  }, [])

export const getFilters = ({ filters, filterType }) =>
  filters.filter(({ type }) => type === filterType)

export const getCheckedFilters = ({ filters, filterType }) =>
  getFilters({ filters, filterType }).filter(({ checked }) => !!checked)

export const getCheckedOffers = ({ filters, filterType }) =>
  getCheckedFilters({ filters, filterType }).map(filter => filter.offers)

const enableProviderFilters = ({ filters }) => {
  return filters.map(filter => {
    if (filter.type === FEATURE) return filter

    return {
      ...filter,
      enabled: true
    }
  })
}

export const handleFeatureFilters = ({ filters }) => {
  const featureFilters = getFilters({ filters, filterType: FEATURE })
  const providerFilters = getFilters({ filters, filterType: PROVIDER })
  const checkedFeatureOffers = getCheckedOffers({
    filters,
    filterType: FEATURE
  })

  const commonOffersId = getCommonOffersId({
    checkedOffers: checkedFeatureOffers
  })

  const activeFeatureFiltersIds = featureFilters
    ?.filter(({ offers }) =>
      offers.some(offerId => commonOffersId?.includes(offerId))
    )
    .map(({ id }) => id)

  const activeProviderFiltersIds = providerFilters
    ?.filter(({ offers }) =>
      offers.some(offerId => commonOffersId?.includes(offerId))
    )
    .map(({ id }) => id)

  if (!activeFeatureFiltersIds.length) {
    return enableProviderFilters({ filters })
  }

  const newFilters = filters.map(filter => {
    if (filter.type === FEATURE && activeFeatureFiltersIds.includes(filter.id)) return filter
    if (filter.type === PROVIDER && activeProviderFiltersIds.includes(filter.id)) return filter

    return {
      ...filter,
      enabled: false,
      checked: false
    }
  })
  return newFilters
}

export const handleProviderFilters = ({ filters }) => {
  const offersId = getCheckedOffers({ filters, filterType: PROVIDER }).flat()

  if (!offersId.length) return handleFeatureFilters({ filters })

  const featureFilters = getFilters({
    filters,
    filterType: FEATURE
  })

  const providerFilters = getFilters({
    filters,
    filterType: PROVIDER
  })

  const activeProviderFiltersIds = featureFilters.map(filter => {
    const isFilterEnabled = offersId.length
      ? filter.offers.some(id => offersId.includes(id))
      : true

    return {
      ...filter,
      enabled: isFilterEnabled
    }
  })

  return [...activeProviderFiltersIds, ...providerFilters]
}
